<template>
  <div class="w-full">
    <vs-row vs-type="flex" vs-justify="center" vs-align="center">
      <vs-col vs-w="12" vs-type="flex" vs-align="center" vs-justify="center">
        <vx-card no-shadow card-border class="w-full p-2">
          <vs-row vs-type="flex" vs-justify="flex-start" vs-align="center">
            <vs-col
              vs-w="12"
              vs-type="flex"
              vs-align="center"
              vs-justify="flex-start"
            >
              <h4 style="color: #666">Ordem de serviço</h4>
            </vs-col>

            <vs-col vs-type="flex" vs-align="center" vs-justify="flex-start">
              <div class="mx-1">
                <label style="color: black; font-size: 0.85rem" class="pl-2">
                  Selecione uma ordem de serviço
                </label>
                <el-select
                  v-model="reportModel.id_os"
                  v-validate="'required'"
                  filterable
                  :popper-append-to-body="true"
                  name="id_pest"
                  class="w-full"
                  @change="$emit('update-id-os')"
                >
                  <el-option
                    v-for="service in services"
                    :value="service.id"
                    :label="service.id"
                    :key="service.id"
                  ></el-option>
                </el-select>
              </div>
            </vs-col>
          </vs-row>
        </vx-card>
      </vs-col>
    </vs-row>
  </div>
</template>
<script>
export default {
  props: {
    reportModel: Object
  },
  data() {
    return {
      services: []
    };
  },

  methods: {
    async loadServices() {
      try {
        this.services = await this.$http.get(
          `list-serices-by-device/${this.reportModel.deviceId}`
        );
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    }
  },

  async mounted() {
    await this.loadServices();
  }
};
</script>
