<template>
  <div id="loader_report_pests" class="w-full vs-con-loading__container">
    <vs-row vs-type="flex" vs-justify="center" vs-align="center">
      <vs-col vs-w="6" vs-type="flex" vs-align="center" vs-justify="flex-start">
        <h4 style="color: #666">Pragas</h4>
      </vs-col>
      <vs-col vs-w="6" vs-type="flex" vs-align="center" vs-justify="center">
        <div class="mx-1">
          <vs-button
            icon="edit"
            type="relief"
            color="warning"
            size="small"
            @click="saveDevicePests"
          >
            Salvar
          </vs-button>
        </div>
      </vs-col>
    </vs-row>

    <vs-row vs-type="flex" vs-justify="center" vs-align="center">
      <vs-col vs-w="12" vs-type="flex" vs-align="center" vs-justify="center">
        <div class="mx-1">
          <label style="color: black; font-size: 0.85rem" class="pl-2">
            Praga
          </label>
          <el-select
            v-model="pest.id_praga"
            v-validate="'required'"
            filterable
            :popper-append-to-body="false"
            name="id_pest"
            class="w-full"
          >
            <el-option
              v-for="pest in pests"
              :value="pest.id"
              :label="pest.nome"
              :key="pest.id"
            ></el-option>
          </el-select>
        </div>
      </vs-col>

      <vs-col vs-w="12" vs-type="flex" vs-align="center" vs-justify="center">
        <div class="mx-1">
          <label style="color: black; font-size: 0.85rem" class="pl-2"> </label>
          <vs-input-number
            color="success"
            v-validate="'required|numeric|min_value:1'"
            name="quantity"
            label="Quantidade."
            min="0"
            value="1"
            v-model="pest.quantidade"
          />
        </div>
      </vs-col>
      <vs-col vs-w="12" vs-type="flex" vs-justify="center" vs-align="center">
        <vs-button
          icon="add"
          @click="submitForm()"
          type="relief"
          color="success"
          size="small"
          class="mt-4"
        >
          Adicionar
        </vs-button>
      </vs-col>
    </vs-row>
    <vs-row vs-type="flex" vs-justify="center" v-if="devicePests.length > 0">
      <vs-col vs-w="12" vs-type vs-justify="center" vs-align="center">
        <div class="w-full">
          <vs-row vs-type="flex" vs-justify="center">
            <vs-col
              vs-w="12"
              vs-type
              vs-justify="center"
              vs-align="center"
              v-for="(pest, index) in devicePests"
              :key="index"
            >
              <div class="card mt-3" style="font-size: 12px">
                <vs-list-item
                  :title="`${pest.praga}: ${pest.quantidade} qtd. `"
                >
                  <vs-button
                    @click="removePest(pest.id_praga)"
                    type="relief"
                    size="small"
                    icon="delete"
                    color="danger"
                  ></vs-button>
                </vs-list-item>
              </div>
            </vs-col>
          </vs-row>
        </div>
      </vs-col>
    </vs-row>
    <vs-row vs-type="flex" vs-justify="center" v-else class="pt-3">
      <vs-col vs-w="12" vs-type vs-justify="center" vs-align="center">
        <span style="color: grey !important; font-weight: 1000 !important">
          <vs-col
            vs-w="12"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
          >
            <vs-icon size="large" icon="sentiment_dissatisfied"></vs-icon>
          </vs-col>
          <vs-col
            class="mt-2"
            vs-w="12"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
          >
            <span>NÃO HÁ PRAGAS CADASTRADAS</span>
          </vs-col>
        </span>
      </vs-col>
    </vs-row>
  </div>
</template>
<script>
import components from "@/components/default/exports.js";

export default {
  props: {
    reportModel: Object,
    device: Object
  },

  components: { ...components },

  data() {
    return {
      pest: {},
      pests: [],
      devicePests: [],
      loaderContainer: "#loader_report_pests"
    };
  },

  async mounted() {
    await this.getPests();
    await this.loadDevicePests();
    this.pest = this.getNewObj();
  },

  methods: {
    async getPests() {
      this.pests = await this.$http.get("praga");
    },

    getNewObj() {
      return {
        quantidade: 1
      };
    },

    async loadDevicePests() {
      await this.$vs.loading({
        container: this.loaderContainer,
        scale: 0.6
      });
      try {
        this.devicePests = await this.$http.post(`list-device-pests`, {
          id_os: this.reportModel.id_os,
          id_dispositivo: this.device.id
        });
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close(
          `${this.loaderContainer} > .con-vs-loading`
        );
      }
    },

    async submitForm() {
      return new Promise(() => {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.addPest();
          } else {
            this.$vs.notify({
              title: "Preencha todos os campos",
              text: "Os campos não devem ser vazios!",
              color: "danger"
            });
          }
        });
      });
    },

    async addPest() {
      try {
        const pestIndex = this.devicePests.findIndex(
          pest => pest.id_praga == this.pest.id_praga
        );
        if (pestIndex === -1) {
          const pest = this.pests.find(p => p.id == this.pest.id_praga);
          this.devicePests.push({ ...this.pest, praga: pest.nome });
        } else {
          this.devicePests[pestIndex].quantidade += this.pest.quantidade;
        }
        this.pest = this.getNewObj();
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },

    async removePest(pestId) {
      try {
        const pestIndex = this.devicePests.findIndex(
          pest => pest.id_praga == pestId
        );
        if (pestIndex !== -1) {
          this.devicePests.splice(pestIndex, 1);
        }
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },

    async saveDevicePests() {
      await this.$vs.loading({
        container: this.loaderContainer,
        scale: 0.6
      });
      try {
        const requestData = {
          ...this.device,
          id_dispositivo: this.reportModel.deviceId,
          id_os: this.reportModel.id_os,
          devicePests: this.devicePests
        };
        const updatedEntity = this.$http.put(
          `update-device-pests`,
          requestData
        );
        await this.$vs.notify(this.$notify.Success);
      } catch (err) {
        console.log(err);
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close(
          `${this.loaderContainer} > .con-vs-loading`
        );
      }
    }
  }
};
</script>
