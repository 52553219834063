<template>
  <div class="w-full p-5" v-if="device && device.id">
    <div id="loader_device_report" class="vs-con-loading__container">
      <vs-row
        vs-w="12"
        vs-type="flex"
        vs-align="center"
        vs-justify="flex-start"
      >
        <vs-col vs-w="12" vs-type="flex" vs-align="center" vs-justify="center">
          <vx-card no-shadow card-border class="p-2">
            <div class="w-full">
              <vs-col
                vs-w="12"
                vs-type="flex"
                vs-align="center"
                vs-justify="flex-start"
              >
                <h4 style="color: #666">Detalhes do Dispositivo</h4>
              </vs-col>
              <vs-col
                vs-w="12"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
              >
                <span style="font-size: 14px" class="mb-1">
                  <b>Sequencia:</b>
                  {{ device.sequencia }}
                </span>
              </vs-col>
              <vs-col
                vs-w="12"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
              >
                <span style="font-size: 14px" class="mb-1">
                  <b>Tipo:</b>
                  {{ device.dispositivoTipo }}
                </span>
              </vs-col>
              <vs-col
                vs-w="12"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
              >
                <span style="font-size: 14px" class="mb-1">
                  <b>Área:</b>
                  {{ device.area }}
                </span>
              </vs-col>
              <vs-col
                vs-w="12"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
              >
                <span style="font-size: 14px" class="mb-1">
                  <b>Consumido:</b>
                  {{
                    device.consumido === null
                      ? "-"
                      : device.consumido
                      ? "Sim"
                      : "Não"
                  }}
                </span>
              </vs-col>
              <vs-col
                vs-w="12"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
              >
                <span style="font-size: 14px" class="mb-1">
                  <b>Cliente:</b>
                  {{ device.cliente }}
                </span>
              </vs-col>
              <vs-col
                vs-w="12"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
              >
                <span style="font-size: 14px" class="mb-1">
                  <b>Contrato:</b>
                  {{ device.id_contrato }}
                </span>
              </vs-col>
              <vs-col
                vs-w="12"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
              >
                <span style="font-size: 14px" class="mb-1">
                  <b>Endereço:</b>
                  {{ device.endereco }}
                </span>
              </vs-col>
            </div>
          </vx-card>
        </vs-col>
      </vs-row>
      <vs-row
        vs-w="12"
        vs-type="flex"
        vs-align="center"
        vs-justify="flex-start"
      >
        <vs-col
          vs-w="12"
          class="mt-1"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          v-if="reportModel.deviceId"
        >
          <vx-card no-shadow card-border class="p-2 mt-5">
            <select-os
              :device="device"
              :report-model="reportModel"
              @update-id-os="reRenderReportPests++"
            ></select-os>
          </vx-card>
        </vs-col>
        <vs-col
          vs-w="12"
          class="mt-1"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          v-if="reportModel.id_os"
        >
          <vx-card no-shadow card-border class="p-2 mt-5">
            <report-pests
              :key="reRenderReportPests"
              :device="device"
              :report-model="reportModel"
            ></report-pests>
          </vx-card>
        </vs-col>
      </vs-row>
    </div>
  </div>
</template>

<script>
import ReportPests from "./reportPests.vue";
import SelectOs from "./select-os.vue";

export default {
  data() {
    return {
      device: {},
      loaderContainer: "#loader_device_report",
      reRenderReportPests: 0,
      reportModel: {
        id_os: null,
        deviceId: null
      }
    };
  },

  components: { ReportPests, SelectOs },

  async mounted() {
    await this.loadDevice();
  },

  methods: {
    async loadDevice() {
      try {
        const decriptedDeviceId = Buffer.from(this.$route.query.id, "base64");
        if (!/^\d+$/.test(decriptedDeviceId)) {
          this.reportModel.hasErrors = true;
          const error = "Dispositivo não encontrado!";
          this.$vs.notify({
            title: "Erro",
            text: error,
            color: "danger"
          });
          throw error;
        }

        this.reportModel.deviceId = decriptedDeviceId;
        this.device = await this.$http.get(
          `device/${this.reportModel.deviceId}`
        );
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    }
  }
};
</script>
